import { getFromLocalStorage, setInLocalStorage } from '@/lib/localStorage';
import { useCallback, useEffect, useState } from 'react';

/**
 * The zip that the user has manually set to be used (assumed to be valid US zip)
 */
export const useCustomZip = () => {
  const [zipAddress, setZipAddress] = useState<{
    zip: string | undefined;
    address: string | undefined;
  }>({
    zip: undefined,
    address: undefined,
  });

  useEffect(() => {
    const zip = getFromLocalStorage('postal');
    const address = getFromLocalStorage('address');
    setZipAddress({ zip, address });
  }, []);

  const setCustomZip = useCallback(
    ({ postal, address }: { postal: string; address?: string }) => {
      setInLocalStorage('postal', postal);
      setInLocalStorage('address', address);
      setZipAddress({ zip: postal, address });
    },
    []
  );

  const clearCustomZip = () => {
    setInLocalStorage('postal', undefined);
    setInLocalStorage('address', undefined);
    setZipAddress({ zip: undefined, address: undefined });
  };

  return {
    setCustomZip,
    clearCustomZip,
    zip: zipAddress.zip,
    address: zipAddress.address,
  };
};
