import { useEffect } from 'react';

export const useLockScroll = (lock: boolean) => {
  useEffect(() => {
    if (lock && window) {
      const { scrollY } = window;

      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%'; // To prevent layout shifting
    } else {
      const scrollY = (document.body.getBoundingClientRect().top || 0) * -1;

      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      window.scrollTo(0, scrollY);
    }
  }, [lock]);
};
