import { useCustomZip } from '@/hooks/useCustomZip';
import { Address, useLocationZip } from '@/hooks/useLocationZip';
import { DEFAULT_US_ZIP_CODE } from '@/lib/constants';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

interface UserZipType {
  userZip: string | undefined;
  userAddress: Address | undefined;
  loading: boolean;
  error: boolean;
}

interface CustomZipType {
  customZip: string | undefined;
  customAddress: string | undefined;
  setCustomZip: ({
    postal,
    address,
  }: {
    postal: string;
    address?: string | undefined;
  }) => void;
  clearCustomZip: () => void;
}

interface ZipContextValue extends UserZipType, CustomZipType {
  preferredZip: string;
}

const ZipContext = createContext<ZipContextValue | null>(null);

export const ZipProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locationAddress, loading, error } = useLocationZip();

  const {
    setCustomZip,
    clearCustomZip,
    zip: customZip,
    address: customAddress,
  } = useCustomZip();

  const preferredZip: string = useMemo(() => {
    if (customZip) {
      return customZip;
    }
    if (locationAddress?.zip && locationAddress?.country === 'US') {
      return locationAddress.zip;
    }
    return DEFAULT_US_ZIP_CODE;
  }, [locationAddress, customZip]);

  const contextValue = useMemo(() => {
    return {
      userZip: locationAddress?.zip,
      userAddress: locationAddress,
      loading,
      error,
      customZip,
      customAddress,
      setCustomZip,
      clearCustomZip,
      preferredZip,
    };
  }, [
    locationAddress,
    loading,
    error,
    customZip,
    customAddress,
    setCustomZip,
    clearCustomZip,
    preferredZip,
  ]);

  return (
    <ZipContext.Provider value={contextValue}>{children}</ZipContext.Provider>
  );
};

export const useZipContext = () => {
  const context = useContext(ZipContext);
  if (!context) {
    throw new Error('useZipContext must be used within a ZipProvider');
  }
  return context;
};
